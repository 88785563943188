import axios from 'axios'
import qs from 'qs'
axios.defaults.timeout = 200000;
// axios.defaults.baseURL = process.env.VUE_APP_API_URL + "/";
const baseURL = 'https://rl.hbyouke.com/prod-api/';
// const baseURL = 'http://192.168.1.116:8080/';
// const baseURL = 'http://192.168.1.112:8080/';
// const baseURL = process.env.VUE_APP_API_URL;
// post
export function postApi(url, data, configs) {
	// var configs = {
	// headers: {
	// 	'Content-Type': 'application/json;charset=UTF-8',
	// 	Authorization: localStorage.getItem('token')
	// }
	// };
	return axios.post(baseURL + url, data, configs)
		.then(response => {
			switch (response.status) {
				case 200: //200 成功
					return response.data;
				case 400: //400 为提示类型错误
					this.$message({
						message: response.data.msg,
						type: 'warning'
					});
					return Promise.reject(response.data.msg || 'error');
				case 500:
					return Promise.reject('网络繁忙');
					break;
				default:
					return Promise.reject(response.data.msg || 'error');
			}
		})
		.catch((msg) => {
			return Promise.reject(msg || 'error')
		});
}
// put
export function putApi(url, data, configs) {
	return axios.put(baseURL + url, data, configs)
		.then(response => {
			// console.log(response);
			switch (response.status) {
				case 200: //200 成功
					return response.data;
				case 400: //400 为提示类型错误
					this.$message({
						message: response.data.msg,
						type: 'warning'
					});
					return Promise.reject(response.data.msg || 'error');
				case 500:
					return Promise.reject('网络繁忙');
					break;
				default:
					return Promise.reject(response.data.msg || 'error');
			}
		})
		.catch((msg) => {
			return Promise.reject(msg || 'error')
		});
}
//get
export function getApi(url, params) {
	return axios.get(baseURL + url, params)
		.then(response => {
			switch (response.status) {
				case 200: //200 成功
					return response.data;
				case 400: //400 为提示类型错误
					this.$message({
						message: response.data.msg,
						type: 'warning'
					});
					return Promise.reject(response.data.msg || 'error');
				case 500:
					return Promise.reject('网络繁忙');
					break;
				default:
					return Promise.reject(response.data.msg || 'error');
			}
		})
		.catch((msg) => {
			return Promise.reject(msg || 'error')
		});
}
//delete
export function delApi(url, params) {
	return axios.delete(baseURL + url, params)
		.then(response => {
			switch (response.status) {
				case 200: //200 成功
					return response.data;
				case 400: //400 为提示类型错误
					this.$message({
						message: response.data.msg,
						type: 'warning'
					});
					return Promise.reject(response.data.msg || 'error');
				case 500:
					return Promise.reject('网络繁忙');
					break;
				default:
					return Promise.reject(response.data.msg || 'error');
			}
		})
		.catch((msg) => {
			return Promise.reject(msg || 'error')
		});
}