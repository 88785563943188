import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios'
import App from './App.vue'
import router from './router'
import locale from 'element-ui/lib/locale/lang/en'
import less from 'less'
import  * as http  from './utils/request.js';
Vue.prototype.$http = http;
Vue.config.productionTip = false
Vue.use(ElementUI, { locale })
Vue.prototype.$axios = axios;
Vue.use(less)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
